// @import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";
@import url("https://fonts.googleapis.com/css?family=Open+Sans:400italic,700italic,400,700");
@import "variables";
@import "bootstrap";
@import "ui-cropper";
@import "switch";
@import "../bower/ng-tags-input/ng-tags-input";
@import "../bower/ng-tags-input/ng-tags-input.bootstrap";
// @import "../bower/bootswatch/flatly/bootstrap.min";
// @import "../bower/bootswatch/spacelab/bootstrap.min";
// @import "../bower/AdminLTE/dist/css/AdminLTE.min";
// @import "../bower/AdminLTE/dist/css/skins/skin-blue.min";
@import "../bower/font-awesome/css/font-awesome.min";
@import "../bower/nprogress/nprogress";
@import "../bower/bootstrap-select/dist/css/bootstrap-select.min";
@import "../bower/bootstrap-datepicker/dist/css/bootstrap-datepicker.min";
@import "../bower/animate.css/animate";
@import "../bower/ladda/dist/ladda-themeless.min";
@import "../bower/hint.css/hint";

@import "equipment";
@import "style";
@import "program";
@import "search";
@import "tooltip";