// Цвета
$gray 				: #999999;
$gray-opacity       : rgba(0, 0, 0, 0.3) !important;
$gray-high-opacity  : rgba(0, 0, 0, 0.1);
$blue				: #337AB7;
$green-main			: #ce171f; // new bootstrap
$green-light        : lighten($green-main, 10);
$green-dark         : darken($green-main, 10);
$hover_blue			: #23527c;

$promo				: #3A6487;

$red				: #ff0039;
$pink				: #ffcccc;
$orange				: #fbaa33;
$metro_orange		: rgb(251, 170, 51);


$green				: #5cb85c;
$metro_green		: #029A55;
$bright_green		: #5cb85c;

$dark_red			: #A94442;

$hr_gray 			: #eee;
$half_black			: rgba(0, 0, 0, .5);
$quater_black 		: rgba(0, 0, 0, .25);

// Body
$body-bg: #f5f8fa;

// Borders
$laravel-border-color: darken($body-bg, 10%);
$list-group-border: $laravel-border-color;
$navbar-default-border: $laravel-border-color;
$panel-default-border: $laravel-border-color;
$panel-inner-border: $laravel-border-color;

// Brands
$brand-primary: #3097D1;
$brand-info: #8eb4cb;
$brand-success: #2ab27b;
$brand-warning: #cbb956;
$brand-danger:  #bf5329;

// Typography
$font-family-sans-serif: "Raleway", sans-serif;
$font-size-base: 14px;
$line-height-base: 1.6;
$text-color: #636b6f;

// Navbar
$navbar-default-bg: #fff;

// Buttons
$btn-default-color: $text-color;

// Inputs
$input-border: lighten($text-color, 40%);
$input-border-focus: lighten($brand-primary, 25%);
$input-color-placeholder: lighten($text-color, 30%);

// Panels
$panel-default-heading-bg: #fff;
