.search-icon {
    float: right;
    cursor: pointer;
}

body.modal-open{
  overflow: hidden;
  height: 100% !important;

  .modal{

  }
}

body.modal-open .blur{
  -webkit-filter: blur(4px);
  overflow: hidden;
  height: 100%;
}


#searchModal.modal{
  overflow-y: hidden;
}

#searchModal{
  z-index: 100000;

  .modal-dialog{
    position: absolute;
    left: 25%;
    top: 25%;
    width: 50% !important;
    height: 50% !important;
    margin-top: 0px !important;
  }

  .modal-content{
    padding: 10px 15px;
    height: 100% !important;

    input, input:focus{
      border: none;
      border-bottom: 1px solid #eee;
      width: 100%;
      height:34px;
      font-size: 18px;
      color: #707070;
      box-shadow:none;
      -webkit-box-shadow: none;
      outline:0;
      margin-bottom: 15px;
    }

    input::-webkit-input-placeholder{
      color: #9d9d9d;
    }
  }

  .resultRow{
    margin: 0px;
    padding: 4px 2px;
    color: #9d9d9d;
  }

  .active{
    background: #f5f5f5;
  }

  .resultRow a{
    text-decoration: underline;
    font-size: 16px;
  }
}


#searchResult{
  overflow-y: scroll;
  width: 100%;
  height: 80%;
}


.searchResultWraper{
  position: relative;
  height: 90%;

  .notFound{
    position: absolute;
    left: 0px;
    top: 47%; //для более точного центра текста
    font-size: 18px;
    text-align: center;
    color: #9d9d9d;
    width: 100%;
  }
}



.hide {
  display: none !important;
}